// src/components/Home.js
import React from 'react';

const Home = () => {
  return (
    <section id="home">
      
    </section>
  );
};

export default Home;
