// src/components/Footer.js
import React from 'react';

const Footer = () => {
  return (
    <footer>
      <p>Contact me at: ibrahim.hersi.2@gmail.com</p>
    </footer>
  );
};

export default Footer;
